import React from 'react';
import TopHeader from '../components/TopHeader'
import { useLocation } from "react-router-dom"
import GoogleMapReact from "google-map-react"
import LocationMarkerBusinessDetail from "./LocationMarkerBusinessDetail"
import { useNavigate } from "react-router-dom";

function BusinessSingle() {

    const {state} = useLocation()
    console.log(state)

    const selectedBusinessCategory = state.business_info.category.name
    let relatedEntityCounter = 0

    let name_for_link = state.business_info.name
    name_for_link = name_for_link.replaceAll(" ", "-")
    name_for_link = name_for_link.toLowerCase()

    const navigate = useNavigate();
    const goToBusinessDetails = (business_info, entities_info) => {
        window.scrollTo(0, 0)
        navigate('/business-single', {state: {
            business_info: business_info,
            entities: entities_info
        }})
    }

    return (

        <div className='business-single-wrapper h-100'>
            <TopHeader/>

            <div className="business-info-wrapper lg:py-20  md:py-20 sm:py-0  py-0">
                <div className="business-main-wapper lg:px-20 lg:mx-40 lg:my-20 bg-white h-100 sm:mx-0 sm:my-0 lg:shadow-md">
                   

                    <div className="business-info-inner-wrapper">

                        <h1 className='text-2xl listing-title text-center mb-5'>{state.business_info.name}</h1>
                        <h4 className='business-category text-center mb-5'>{state.business_info.category.name}</h4>

                    </div>

                    <div
                        className="flex flex-wrap -mx-5 overflow-hidden sm:-mx-5 md:-mx-5 lg:-mx-5 xl:-mx-5">

                        <div
                            className="my-5 px-5 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 business-blocks shadow-md">
                            <h2 className='business-block-b-name'>{state.business_info.name}</h2>
                            <p className='business-block-b-details text-black mt-5'>{state.business_info.info}</p>
                        </div>

                        <div
                            className="my-5 px-5 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2"><img class="business-second-image" src={state.business_info.logo} alt=""/></div>

                        <div
                            className="my-5 px-5 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 business-blocks shadow-md">

                            <h2 className='business-block-b-name'>Contact Details</h2>
                            <p className='business-block-b-details text-black'>

                                <ul className="w-full rounded-lg mt-2 mb-3">
                                    <li className="mb-1">
                                        <span className="w-fill flex p-3 pl-3 rounded-lg">
                                            <svg
                                                class="h-8 w-8 text-green-500"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round">
                                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/>
                                                <circle cx="12" cy="10" r="3"/></svg>
                                            <span
                                                className="ml-2 text-lg b-details-btn-text"
                                                title="Address">
                                                <b>Address: </b>
                                                {state.business_info.address}
                                            </span>
                                        </span>
                                    </li>

                                    <li className="mb-1">
                                        <a href={`tel:${state.business_info.phone}`} className="w-fill flex p-3 pl-3 rounded-lg">
                                            <svg
                                                class="h-8 w-8 text-green-500"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                                            </svg>

                                            <span
                                                className="ml-2 text-lg b-details-btn-text"
                                                title="Phone">
                                                <b>Phone: </b>
                                                {state.business_info.phone}
                                            </span>
                                        </a>
                                    </li>

                                    <li className="mb-1">
                                        <a href={`mailto:${state.business_info.email}`} className="w-fill flex p-3 pl-3 rounded-lg">
                                            <svg
                                                class="h-8 w-8 text-green-500"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round">
                                                <path
                                                    d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
                                                <polyline points="22,6 12,13 2,6"/></svg>

                                            <span
                                                className="ml-2 text-lg b-details-btn-text"
                                                title="Email">
                                                <b>Email: </b>
                                                {state.business_info.email}
                                            </span>
                                        </a>
                                    </li>

                                    <li className="mb-1">
                                        <span className="w-fill flex p-3 pl-3 rounded-lg">
                                            <svg
                                                class="h-8 w-8 text-green-500"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round">
                                                <circle cx="12" cy="12" r="10"/>
                                                <polyline points="12 6 12 12 16 14"/></svg>
                                            <span
                                                className="ml-2 text-lg b-details-btn-text"
                                                title="Working Hours">
                                                <b>Working Hours: </b>
                                                {state.business_info.working_hours}
                                            </span>
                                        </span>
                                    </li>

                                    

                                </ul>

                                

                             
                                <a class="text-white px-32 py-3  text-1xl font-mediumtransition duration-300 w-full business-detials-btn" target={'_blank'} rel="noreferrer"
                                    href={state.business_info.page_url + "?utm_source=atf&utm_medium=web&utm_campaign=atf-" + name_for_link + "&utm_content=atf&utm_term=atf"}>
                                        Visit Website</a>
                            </p>

                        </div>

                        <div className="my-5 px-5 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                            {/* <img src="map.png" alt=""/>     */}
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyBr5bbagqBVo3NNL_1vyBhlDhEeoJ5vq3Y'}}
                                center={{lat: parseFloat(state.business_info.lat), lng: parseFloat(state.business_info.lng)}}
                                zoom={17}
                            >
                                <LocationMarkerBusinessDetail />
                            </GoogleMapReact>
                        </div>

                        {/* Related Busiess Div */}

                        <div className="related-business-section-wrapper">
                            <h2 className='text-2xl text-center mb-10 mt-14 related-business-section-title'>Related Businesses From This Category</h2>
                            <div class="flex justify-center items-end space-x-0 space-y-5 w-full related-business-wrapper">
                                {
                                    state.entities.map(entity => {
                                        if (entity.entity_type === "business" && entity.category.name === selectedBusinessCategory && entity.name !== state.business_info.name && relatedEntityCounter < 3) {
                                            relatedEntityCounter++
                                            return <div class="item w-1/3 h-full flex-auto related-card-box shadow-md">
                                                        <img
                                                            src={entity.logo}
                                                            className='business-related-featured-image'
                                                            alt=""/>

                                                        <h2 className='related-business-title mt-2'>{entity.name}</h2>

                                                        <span className="w-fill flex p-3 pl-0 rounded-lg ml-0 mb-5">
                                                            <svg
                                                                class="h-8 w-8 text-green-500"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    stroke-width="2"
                                                                    d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"/>
                                                            </svg>
                                                            <span
                                                                className="ml-2 text-sm b-related-details-btn-text"
                                                                title="Business Category">
                                                                <b>Business Category: </b>
                                                                {entity.category.name}
                                                            </span>
                                                        </span>

                                                        <button class="text-white px-32 py-3  text-1xl font-mediumtransition duration-300 business-detials-btn" 
                                                                onClick={() => goToBusinessDetails(entity, state.entities)}>Learn More</button>
                                                    </div>
                                        } else {
                                            return ""
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BusinessSingle;
