

const TopHeader = () => {
    return (
        <header className="header  md:fixed xm:relative">
            <div className="top-nav-wrapper bg-white p-2">
                <div className="container mx-auto p-2">
                    <div class="flex mx-auto">
                        <div class="w-100">
                            <a href="/">
                                <img
                                    className="object-contain h-10 w-100 float-left mt-0"
                                    src="atf-wide-logo.png"
                                    alt="" /></a>

                        </div>

                    </div>

                </div>
            </div>


        </header>
    )
}

export default TopHeader
