import Select from 'react-select'
import React, { useState } from "react";
import { Link } from 'react-router-dom';







const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected
            ? 'white'
            : 'grey',
        padding: 5
    }),

    singleValue: (provided, state) => {
        const opacity = state.isDisabled
            ? 1
            : 1;
        const transition = 'opacity 300ms';

        return {
            ...provided,
            opacity,
            transition
        };
    }
}

const Header = ({
    states,
    regions,
    onStateChange,
    onRegionChange,
    contentTitle,
    contentDesc,
    currentRegion,
    busChkChng,
    servChkChng,
    projChkChng,
    fishChkChng,
    showRegionPageLink,
    bc,
    fc,
    pc,
    sc
}) => {
    const [isVisible, setIsVisible] = useState(true);
    const toggleVisibility = () => setIsVisible(!isVisible);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };


    const Modal = ({ isOpen, toggleModal }) => {
        if (!isOpen) return null;

        return (
            <div className="modal" onClick={toggleModal}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <button className='text-gray-700 float-right mr-2' onClick={toggleModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline-block align-middle">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                        <span className="align-middle">Close</span>
                    </button>

                    <br />
                    <h2 className='text-gray-700 text-xl'>Map Legend</h2>


                    <div className="map-legends-icons-details mt-6">

                        <div className="icon-list-item">
                            <img src="/map-icons/accommodation-icon.png" alt="Placeholder" />
                            <p className='text-gray-700 text-sm'>Accommodation Icon: Marks locations where lodging or overnight accommodation is available.</p>
                        </div>

                        <div className="icon-list-item">
                            <img src="/map-icons/fishing-spot-icon.png" alt="Placeholder" />
                            <p className='text-gray-700 text-sm'>Fishing Spot Icon: Indicates prime locations for fishing activities.</p>
                        </div>

                        <div className="icon-list-item">
                            <img src="/map-icons/food-icon.png" alt="Placeholder" />
                            <p className='text-gray-700 text-sm'>Food Icon: Shows where food and dining facilities are located.</p>
                        </div>


                        <div className="icon-list-item">
                            <img src="/map-icons/information-icon.png" alt="Placeholder" />
                            <p className='text-gray-700 text-sm'>Information Icon: Points to visitor centers or spots where you can find local information and help.</p>
                        </div>

                        <div className="icon-list-item">
                            <img src="/map-icons/medical-icon.png" alt="Placeholder" />
                            <p className='text-gray-700 text-sm'>Medical Icon: Identifies healthcare and emergency medical services locations.</p>
                        </div>

                        <div className="icon-list-item">
                            <img src="/map-icons/projects-icon.png" alt="Placeholder" />
                            <p className='text-gray-700 text-sm'>Projects Icon: Highlights areas of ongoing projects or development activities.</p>
                        </div>

                        <div className="icon-list-item">
                            <img src="/map-icons/shop-location-icon.png" alt="Placeholder" />
                            <p className='text-gray-700 text-sm'>Shop Location Icon: Designates retail and shopping areas.</p>
                        </div>


                    </div>

                </div>

            </div>
        );
    };


    return (
        <header className="header  md:fixed xm:relative">
            <div className="top-nav-wrapper bg-white p-2">
                <div className="container mx-auto p-2">
                    <div class="flex flex-wrap justify-between space-y-1">
                        <div class="w-50">
                            <a href='https://australiantroutfoundation.com.au/'>
                                <img className="object-contain h-10 w-100 float-left mt-0" src="atf-wide-logo.png" alt="" />
                            </a>
                        </div>

                        <div class="w-50">



                            <a href="https://australiantroutfoundation.com.au/business-directory-registration/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="atf-location-details-btn inline-flex items-center text-white top-left-cta text-black text-sm"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                </svg>
                                <span className='mx-2'>PUT YOURSELF ON THE MAP</span>
                            </a>




                        </div>

                    </div>

                </div>
            </div>





            <div className='filterHeaderWrapper' >

                {isVisible && (

                    <div className="container mx-auto px-5 pt-2  filter-header">


                        <div className="select-control-wrapper pt-1 pb-0 xs:px-2 px-0">

                            <div className="flex flex-wrap">
                                <div className="flex-1 w-100 lg:mr-5 ">
                                    <p class="text-black text-sm">State</p>
                                    <Select
                                        options={states}
                                        styles={customStyles}
                                        placeholder="Select State"
                                        onChange={onStateChange} />
                                </div>
                                <div className="flex-1 w-100 lg:mr-5">
                                    <p class="text-black text-sm">Region</p>
                                    <Select
                                        options={regions}
                                        value={currentRegion}
                                        styles={customStyles}
                                        placeholder="Select Region"
                                        onChange={onRegionChange} />
                                </div>

                                <div className="flex-1 w-100 lg:mr-2">

                                    <p class="text-black text-sm">Filter By</p>

                                    <div class="inline-block">

                                        <div className="atf-map-filter">

                                            <div className="md:w-32 w-20">
                                                <label class="inline-flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        class="form-checkbox atf-brand-color"
                                                        checked={bc}
                                                        onChange={busChkChng} />
                                                    <span class="ml-2">
                                                        <p className="text-black md:text-sm text-xs">Business</p>
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="md:w-32 w-16 mr-2">
                                                <label class="inline-flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        class="form-checkbox atf-brand-color"
                                                        checked={sc}
                                                        onChange={servChkChng} />
                                                    <span class="ml-2">
                                                        <p className="text-black md:text-sm text-xs">Service</p>
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="md:w-32 w-20">
                                                <label class="inline-flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        class="form-checkbox atf-brand-color"
                                                        checked={pc}
                                                        onChange={projChkChng} />
                                                    <span class="ml-2">
                                                        <p className="text-black md:text-sm text-xs">Projects</p>
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="md:w-32 w-24">
                                                <label class="inline-flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        class="form-checkbox atf-brand-color"
                                                        checked={fc}
                                                        onChange={fishChkChng} />
                                                    <span class="ml-2">
                                                        <p className="text-black md:text-sm text-xs">Fishing Spot</p>
                                                    </span>
                                                </label>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="checkbox-wrapper mb-2">
                            <div className="grid grid-flow-col gap-3">
                                <div className="bg-gray-50 p-3 col-span-3 px-2 mt-2">
                                    <div className="state-region-info-box">
                                        <h2 className="sr-info-box-title text-black text-l pb-0">{contentTitle}</h2>
                                        {showRegionPageLink
                                            ? <p className="text-gray-700 md:text-sm text-xs">{contentDesc}...
                                                <a className='read-more-btn' href={currentRegion.page_url} target={'_blank'} rel="noreferrer">
                                                    Read more</a>
                                            </p>
                                            : <p className="text-gray-700">{contentDesc}</p>
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )}


                <div className="filterHeaderbtnWrapper pt-0 container px-5 mx-auto w-full my-2">
                    <button className='atf-location-details-btn flex justify-center align-middle' onClick={toggleVisibility}>

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
                        </svg>

                        <span className='ml-2'>{isVisible ? 'Hide Filter Bar' : 'Show Filter Bar'}</span>


                    </button>


                    <button className="text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center map-legends" onClick={toggleModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                        </svg>

                        <span className='ml-2'>Map Legend</span>
                    </button>

                    {/* Modal component */}
                    <Modal isOpen={isModalOpen} toggleModal={toggleModal} />
                </div>





            </div>












        </header>
    )
}

export default Header;

