import { useState } from "react"
import GoogleMapReact from "google-map-react"
import LocationMarker from "./LocationMarker"
import LocationInfoBox from "./LocationInfoBox"



const Map = ({ center, zoom, myPoints }) => {

    const [locationInfo, setLocationInfo] = useState(null)
    const [showLocationInfoBox, setShowLocationInfoBox] = useState(false)

    const onMapIconClick = (entity) => {
        setLocationInfo(entity)
        setShowLocationInfoBox(true)
    }


    const defaultMapOptions = {
        fullscreenControl: false,
    };

    return (
        <div className="map">

            


            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyBr5bbagqBVo3NNL_1vyBhlDhEeoJ5vq3Y' }}
                center={center}
                zoom={zoom}
                defaultOptions={defaultMapOptions}
            >
                {myPoints.map((entity, index) =>
                    <LocationMarker mapIcon={entity.map_icon} lat={entity.lat} lng={entity.lng} onClick={() => onMapIconClick(entity)} />)
                }
            </GoogleMapReact>


            {locationInfo && showLocationInfoBox && <LocationInfoBox setShowLocationInfoBox={setShowLocationInfoBox} info={locationInfo} entities={myPoints}></LocationInfoBox>}



        </div>
    )
}

Map.defaultProps = {
    center: {
        lat: -32.2569,
        lng: 148.6011
    },
    zoom: 5

}

export default Map
