import { useNavigate } from "react-router-dom";

const LocationInfoBox = ({ setShowLocationInfoBox, info, entities }) => {

    let name_for_link = info.name
    name_for_link = name_for_link.replaceAll(" ", "-")
    name_for_link = name_for_link.toLowerCase()
    const entity_type = info
        .entity_type
        .replaceAll("_", " ")
        .replace("atf", "ATF")

    let details

    const closeLocationInfoBox = () => {
        setShowLocationInfoBox(false)
    }

    const navigate = useNavigate();

    const goToBusinessDetails = (business_info, entities_info) => {
        navigate('/business-single', {state: {
            business_info: business_info,
            entities: entities_info
        }})
    }

    if (entity_type === "business") {
        details = <ul>
            <li><img src={info.logo} alt=""/></li>
            <li>
                <strong>Name:
                </strong>

                <span className="ml-1">
                    {info.name}
                </span>
                
            </li>
            <li className="capitalize">
                <strong>Category:
                </strong> <span className="ml-1">
                    {entity_type}
                </span>
                 </li>
            <li><br/>
                <button
                    class="atf-location-details-btn"
                    rel="noreferrer"
                    onClick={() => goToBusinessDetails(info, entities)}
                    /**/>Learn More</button>
            </li>
        </ul>
    } else if (entity_type !== "atf project") {

        details = <ul>
            <li><img src={info.logo} alt=""/></li>
            <li>
                <strong>Name:
                </strong>

                <span className="ml-1">
                    {info.name}
                </span>                
            </li>
            <li className="capitalize">
                <strong>Category:
                </strong> <span className="ml-1">
                    {entity_type}
                </span>
                 </li>
            {info.phone ?
                <li>
                    <strong>Phone:
                    </strong> <span className="ml-1">
                        {info.phone}
                    </span>
                </li>
                :
                ''}

            {info.working_hours ?
            <li>
                <strong>Working hours:
                </strong>
                <span className="ml-1">
                    {info.working_hours}
                </span>

            </li> : ''}

            {info.info ?
            <li>
                <strong>Details:
                </strong>
                <span className="ml-1">
                    {info.info}
                </span>
            </li> : ''}

            {info.address ? 
                <li>
                    <strong>Address:
                    </strong>
                    
                    <span className="ml-1">
                        {info.address}
                    </span>
                </li> : ''}
            {info.page_url ?
                    <li><br/>
                        <a
                            class="atf-location-details-btn"
                            rel="noreferrer"
                            target="_blank"
                            href={info.page_url + "?utm_source=atf&utm_medium=web&utm_campaign=atf-" + name_for_link + "&utm_content=atf&utm_term=atf"}>Learn More</a>
                    </li> : ''}
        </ul>
    } else {
        details = <ul>
            <li><img src={info.logo} alt=""/></li>
            <li>
                <strong>Name:
                </strong>

                <span className="ml-1">
                    {info.name}
                </span>
                
            </li>
            <li className="capitalize">

                <strong>Category:
                </strong>

                <span className="ml-1">
                    {entity_type}
                </span>

                </li>
            {info.info ?
                <li>
                    <strong>Details:
                    </strong>

                    <span className="ml-1">
                        {info.info}
                    </span>

                </li> : ''}
            {info.address
                ? <li>

                        <strong>Address:
                    </strong>
                    
                    <span className="ml-1">
                        {info.address}
                    </span>

                        </li>
                : ''}
            {info.page_url 
                ? <li>
                    <br />
                    <a
                        class="atf-location-details-btn"
                        rel="noreferrer"
                        target="_blank"
                        href={info.page_url + "?utm_source=atf&utm_medium=web&utm_campaign=atf-" + name_for_link + "&utm_content=atf&utm_term=atf"}>Learn More</a>
                </li> : ''}
        </ul>
    }

    return (
        <div className="location-info">

            <div className="location-header-elements">
                <h2 className="location-info-header">Location Information</h2>
                <button className="infoCloseButton" onClick={closeLocationInfoBox}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>
            </div>

            {details}
        </div>
    )
}

export default LocationInfoBox
