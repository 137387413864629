import {Routes, Route, BrowserRouter as Router} from 'react-router-dom';
import Home from './components/Home'
import BusinessSingle from './components/BusinessSingle'

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path='/' element={< Home />}/>
                <Route path='/business-single' element={< BusinessSingle />}/>
            </Routes>
        </Router>
    )

}

export default App;
