const LocationMarker = ({lng, onClick, lat, mapIcon}) => {
    return (
        <div className="location-marker" onClick={onClick}>
            {/* <Icon icon={mapIcon} className="location-icon"/> */}
            <img src={mapIcon} alt=""/>
        </div>
    )
}

export default LocationMarker
