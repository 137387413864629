import { useState, useEffect } from 'react'
import Map from '../components/Map'
import Loader from '../components/Loader'
import Header from '../components/Header'

function Home() {
    const originalContTitle = "Showing all states"
    const originalContDesc = "Select a state to see its information"

    const [center,
        setCenter] = useState({ lat: -37.0201, lng: 144.9646 })
    const [zoom,
        setZoom] = useState(7)

    const [stateData,
        setStateData] = useState([])
    const [currentState,
        setCurrentState] = useState({})
    const [currentRegion,
        setCurrentRegion] = useState({})
    const [regionData,
        setRegionData] = useState([])
    const [initialRegions,
        setInitialRegions] = useState([])
    const [entities,
        setEntities] = useState([])
    const [initialEntities,
        setInitialEntities] = useState([])
    const [loading,
        setLoading] = useState(true)
    const [contentTitle,
        setContentTitle] = useState(originalContTitle)
    const [contentDesc,
        setContentDesc] = useState(originalContDesc)
    const [bc,
        setBc] = useState(true)
    const [sc,
        setSc] = useState(true)
    const [pc,
        setPc] = useState(true)
    const [fc,
        setFc] = useState(true)
    const [removedEntities,
        setRemovedEntities] = useState([])
    const [showRegionPageLink,
        setShowRegionPageLink] = useState(false)

    useEffect(() => {
        setLoading(true)
        const fetchStates = async () => {
            // setLoading(true)
            const res = await fetch('https://admin.australiantroutfoundation.com.au/api/states/')
            const results = await res.json()

            let outputObj = [
                {
                    value: "ALL",
                    label: "All"
                }
            ]

            results.forEach(state => {
                outputObj.push({ value: state.url, label: state.name, center_lat: state.lat, center_lng: state.lng, info: state.info })
            });

            // console.log(outputObj)
            setStateData(outputObj)
            // setLoading(false)
        }

        const fetchRegions = async () => {
            // setLoading(true)
            const res = await fetch('https://admin.australiantroutfoundation.com.au/api/regions/')
            const results = await res.json()

            let outputObj = []

            results.forEach(region => {
                outputObj.push({
                    center_lat: region.lat,
                    center_lng: region.lng,
                    label: region.name + ", " + region.state.name,
                    details: region.info,
                    state: region.state,
                    value: region.url,
                    page_url: region.page_url
                })
            })

            // console.log(outputObj) setRegionData([{ value: "ALL", label: "All" }])
            setInitialRegions(outputObj)
            // setLoading(false)
        }

        const fetchEntities = async () => {
            // setLoading(true)
            const businesses = await fetch('https://admin.australiantroutfoundation.com.au/api/businesses/')
            const services = await fetch('https://admin.australiantroutfoundation.com.au/api/essentials/')
            const fishingSpots = await fetch('https://admin.australiantroutfoundation.com.au/api/fishingSpots/')
            const atfProjects = await fetch('https://admin.australiantroutfoundation.com.au/api/projects/')
            const business_results = await businesses.json()
            const service_results = await services.json()
            const fishingSpot_results = await fishingSpots.json()
            const project_results = await atfProjects.json()

            let results = []

            business_results.forEach(business => {
                results.push({
                    entity_type: "business",
                    name: business.name,
                    category: business.category,
                    website: business.website,
                    email: business.email,
                    lat: business.lat,
                    lng: business.lng,
                    info: business.info,
                    region: business.region,
                    logo: business.logo,
                    map_icon: business.map_icon,
                    address: business.address,
                    page_url: business.page_url,
                    phone: business.phone,
                    working_hours: business.working_hours
                })
            })

            service_results.forEach(business => {
                results.push({
                    entity_type: "service",
                    name: business.name,
                    lat: business.lat,
                    lng: business.lng,
                    info: business.info,
                    region: business.region,
                    logo: business.logo,
                    map_icon: business.map_icon,
                    address: business.address,
                    page_url: business.page_url,
                    phone: business.phone,
                    working_hours: business.working_hours
                })
            })

            fishingSpot_results.forEach(business => {
                results.push({
                    entity_type: "fishing_spot",
                    name: business.name,
                    lat: business.lat,
                    lng: business.lng,
                    info: business.info,
                    region: business.region,
                    logo: business.logo,
                    map_icon: business.map_icon,
                    address: business.address,
                    page_url: business.page_url,
                    phone: business.phone,
                    working_hours: business.working_hours
                })
            })

            project_results.forEach(business => {
                results.push({
                    entity_type: "atf_project",
                    name: business.name,
                    lat: business.lat,
                    lng: business.lng,
                    info: business.info,
                    region: business.region,
                    logo: business.thumbnail,
                    map_icon: business.map_icon,
                    page_url: business.page_url
                })
            })

            // console.log(results)

            setEntities(results)
            setInitialEntities(results)
            // setLoading(false)
        }

        fetchStates()
        fetchRegions()
        fetchEntities()
        setLoading(false)
    }, [])

    // we detect a selected state here
    const onStateSelect = selectedOption => {

        setCurrentState(selectedOption)
        setCurrentRegion({ value: "ALL", label: "All" })
        setShowRegionPageLink(false)
        resetAllFilters()

        let filteredRegions = [
            {
                value: "ALL",
                label: "All"
            }
        ]
        let filteredEntities = []

        /**
         * 1. Update the regions select box with regions from selected state only
         * 2. update the map center based on selected state or global center
         * 3. update entities, only show entities belonging to that state
         */
        if (selectedOption.value === "ALL") {
            setCenter({ lat: -32.2569, lng: 148.6011 })
            setZoom(5)

            setContentTitle(originalContTitle)
            setContentDesc(originalContDesc)

            filteredEntities = initialEntities
        } else {
            initialRegions.map(region => (region.state.url === selectedOption.value
                ? filteredRegions.push(region)
                : null))

            stateData.forEach((state) => {
                if (state.value === selectedOption.value) {
                    setCenter({
                        lat: parseFloat(state.center_lat),
                        lng: parseFloat(state.center_lng)
                    })
                    setZoom(7)
                    setContentTitle(state.label)
                    setContentDesc(state.info)
                    return
                }
            })

            initialEntities.map(point => (point.region.state.url === selectedOption.value
                ? filteredEntities.push(point)
                : null))
        }

        setRegionData(filteredRegions)
        setEntities(filteredEntities)
    }

    const onRegionSelect = selectedRegion => {

        console.log(selectedRegion)
        setCurrentRegion(selectedRegion)
        setShowRegionPageLink(true)
        resetAllFilters()

        let filteredEntities = []

        if (selectedRegion.value === "ALL") {
            setCenter({
                lat: parseFloat(currentState.center_lat),
                lng: parseFloat(currentState.center_lng)
            })
            setZoom(7)

            setContentTitle(currentState.label)
            setContentDesc(currentState.info)

            initialEntities.map(point => (point.region.state.url === currentState.value
                ? filteredEntities.push(point)
                : null))
        } else {
            setCenter({
                lat: parseFloat(selectedRegion.center_lat),
                lng: parseFloat(selectedRegion.center_lng)
            })
            setZoom(13)

            setContentTitle(selectedRegion.label)
            setContentDesc(selectedRegion.details)

            initialEntities.map(point => (point.region.url === selectedRegion.value
                ? filteredEntities.push(point)
                : null))
        }

        setEntities(filteredEntities)
    }

    const busChkChng = isChecked => {
        if (isChecked.target.checked) {
            setBc(true)
            filterByEntityType({ type: "business", selected: true })
        } else {
            setBc(false)
            filterByEntityType({ type: "business", selected: false })
        }
    }

    const servChkChng = isChecked => {
        if (isChecked.target.checked) {
            setSc(true)
            filterByEntityType({ type: "service", selected: true })
        } else {
            setSc(false)
            filterByEntityType({ type: "service", selected: false })
        }
    }

    const projChkChng = isChecked => {
        if (isChecked.target.checked) {
            setPc(true)
            filterByEntityType({ type: "atf_project", selected: true })
        } else {
            setPc(false)
            filterByEntityType({ type: "atf_project", selected: false })
        }
    }

    const fishChkChng = isChecked => {
        if (isChecked.target.checked) {
            setFc(true)
            filterByEntityType({ type: "fishing_spot", selected: true })
        } else {
            setFc(false)
            filterByEntityType({ type: "fishing_spot", selected: false })
        }
    }

    const filterByEntityType = (selectedType) => {
        let currentRemoved = removedEntities
        let currentEntities = entities

        if (!selectedType.selected) {
            // push matching elements into deleted list
            currentRemoved = currentRemoved.concat(entities.filter(x => x.entity_type === selectedType.type))
            // remove matching elements from main list
            currentEntities = entities.filter(x => x.entity_type !== selectedType.type)
        } else {
            // restore from deleted list to main list
            currentEntities = currentEntities.concat(currentRemoved.filter(x => x.entity_type === selectedType.type))
            // remove from deleted list
            currentRemoved = currentRemoved.filter(x => x.entity_type !== selectedType.type)
        }

        console.log("Removed entries: ", currentRemoved)
        console.log("Current entries: ", currentEntities)

        setEntities(currentEntities)
        setRemovedEntities(currentRemoved)
    }

    const resetAllFilters = () => {
        setBc(true)
        setPc(true)
        setFc(true)
        setSc(true)
    }

    return (
        <div>
            <Header
                states={stateData}
                regions={regionData}
                onStateChange={onStateSelect}
                onRegionChange={onRegionSelect}
                contentTitle={contentTitle}
                contentDesc={contentDesc}
                currentRegion={currentRegion}
                busChkChng={busChkChng}
                servChkChng={servChkChng}
                projChkChng={projChkChng}
                fishChkChng={fishChkChng}
                showRegionPageLink={showRegionPageLink}
                bc={bc}
                sc={sc}
                fc={fc}
                pc={pc} /> {!loading
                    ? <Map myPoints={entities} center={center} zoom={zoom} />
                    : <Loader />}
        </div>
    );
}

export default Home;